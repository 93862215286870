<template>
  <div style="margin: 100px auto; background: white; width: 500px; border-radius: 10px; overflow: hidden">
    <el-tabs type="card" v-model="activeName" >

      <el-tab-pane label="修改密码" name="first">
        <div style="margin: 20px auto; background-color: #fff; width: 350px; height: 220px; padding: 20px; border-radius: 10px">
          <el-form label-width="120px" size="small" :model="form" :rules="rules" ref="pass">
            <el-form-item label="原密码" prop="password">
              <el-input v-model="form.password" autocomplete="off" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input v-model="form.newPassword" autocomplete="off" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="confirmPassword">
              <el-input v-model="form.confirmPassword" autocomplete="off" show-password></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save">确 定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>

      <el-tab-pane label="忘记密码" name="second">
        <div style="margin: 20px auto; background-color: #fff; width: 500px; height: 220px; padding: 20px; border-radius: 10px">
          <el-form label-width="120px" size="small" :model="pass" ref="pass">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="pass.email" autocomplete="off" ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model="pass.code" autocomplete="off" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="sendEmailCode(2)">获取验证码</el-button>
              <el-button type="primary" @click="passwordBack" style="margin-left: 20px">重置密码</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Password",
  data() {
    return {
      activeName: 'first',
      form: {},
      pass: {},
      code: '',
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      rules: {
        password: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 3, message: '长度不少于3位', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 3, message: '长度不少于3位', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 3, message: '长度不少于3位', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    this.form.username = this.user.username
  },
  methods: {
    save() {
      this.$refs.pass.validate((valid) => {
        if (valid) {
          if (this.form.newPassword !== this.form.confirmPassword) {
            this.$message.error("2次输入的新密码不相同")
            return false
          }
          this.request.post("/user/password", this.form).then(res => {
            if (res.code === '200') {
              this.$message.success("修改成功")
              this.$store.commit("logout")
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    sendEmailCode(type) {
      let email;
      if (type === 1) {
        email = this.user.email
      } else if(type === 2) {
        email = this.pass.email
      }
      if(!email) {
        this.$message.warning("请输入邮箱账号")
        return
      }
      if(!/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(email)) {
        this.$message.warning("请输入正确的邮箱账号")
        return
      }
      // 发送邮箱验证码
      this.request.get("/user/email/" + email + "/" + type).then(res => {
        if (res.code === '200') {
          this.$message.success("发送成功")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    passwordBack() {
      this.request.put("/user/reset", this.pass).then(res => {
        if (res.code === '200') {
          this.$message.success("重置密码成功，新密码为：123，请尽快修改密码")
          this.dialogFormVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader {
  text-align: center;
  padding-bottom: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 138px;
  height: 138px;
  line-height: 138px;
  text-align: center;
}
.avatar {
  width: 138px;
  height: 138px;
  display: block;
}
</style>
